<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      >
    <template #activator="{on}">
      <v-tooltip
          bottom
          >
        <template #activator="{on: tooltipOn}">
          <span v-on="tooltipOn">
            <v-btn
                v-on="on"
                text
                >
              <v-icon class="mr-2">mdi-cog</v-icon>
              Settings
            </v-btn>
          </span>
        </template>
        Settings
      </v-tooltip>
    </template>
    <template #default="dialog">
      <v-card>
        <v-card-title>
          Settings
        </v-card-title>
        <v-card-text class="pb-1">
          <v-checkbox
              v-model="computedDarkMode"
              label="Dark mode"
              hide-details
              />
          <div class="grey--text pt-5">
            Created by Kyle Peyton
          </div>
          <div class="grey--text pt-1">
            v{{ version }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="dialog.value = false"
              >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Vuex from 'vuex'
const version = require('../../package.json').version

export default {
  name: 'Options',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...Vuex.mapGetters('Options', ['settings']),
    computedDarkMode: {
      get() { return this.settings.darkMode },
      set(v) { this.changeSetting({darkMode: !!v}) },
    },
    version() {
      return version
    },
  },
  methods: {
    ...Vuex.mapMutations('Options', ['changeSetting']),
  },
}
</script>
