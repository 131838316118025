<template>
  <div style="height: 100%; display: flex; flex-direction: column; justify-content: space-between">
    <div
        class="guess-words"
        :style="{'--letter-size': `${letterSize}px`}"
        >
      <div
          v-for="(guess,i) in guesses"
          :key="`${guess}-${i}`"
          class="guess-word"
          >
        <div
            v-for="j in maxLetters"
            :key="j"
            class="guess-word-letter"
            :class="{
              'correct-letter': hintsForWord({answer: word, word: guess})[j - 1] === 'correct',
              'close-letter': hintsForWord({answer: word, word: guess})[j - 1] === 'close',
              finished: animationLookup[`${i}-${j - 1}`],
            }"
            >
          {{guess[j - 1] ? guess[j - 1] : ''}}
        </div>
      </div>
      <div
          v-if="maxGuesses > guesses.length"
          class="guess-word"
          :class="{'guess-word-active': !finished}"
          >
        <div
            v-for="j in maxLetters"
            :key="j"
            class="guess-word-letter"
            >
          {{activeGuess[j - 1] ? activeGuess[j - 1] : ''}}
        </div>
      </div>
      <template v-if="maxGuesses - guesses.length - 1 > 0">
        <div
            v-for="i in (maxGuesses - guesses.length - 1)"
            :key="i"
            class="guess-word"
            >
          <div
              v-for="j in maxLetters"
              :key="j"
              class="guess-word-letter"
              >
          </div>
        </div>
      </template>
    </div>
    <div
        class="keyboard-wrapper"
        >
      <div
          class="keyboard-inner-wrapper"
          :style="{width: $vuetify.breakpoint.smAndDown ? 'calc(var(--viewport-width-px) - 20px)' : 'min(50vw, 500px)'}"
          >
        <keyboard
            @keyRelease="key => handleKey({key})"
            :letter-states="letterStates"
            />
      </div>
    </div>
    <share-dialog v-model="showShare" />
  </div>
</template>

<script>
import Vuex from 'vuex'
import dayjs from 'dayjs'
import Keyboard from '@/components/Keyboard.vue'
import ShareDialog from '@/components/ShareDialog.vue'
import utilities from '@/lib/utilities'

export default {
  name: 'GuessBoard',
  components: {
    Keyboard,
    ShareDialog,
  },
  data() {
    return {
      animationLookup: {},
      showShare: false,
    }
  },
  created() {
  },
  mounted() {
    this.checkAndPlayAnimation()
    this.checkAndSetGameMode()
  },
  computed: {
    ...Vuex.mapGetters('Dashboard', ['maxGuesses', 'maxLetters', 'guesses', 'activeGuess', 'finished', 'word']),
    ...Vuex.mapGetters('Config', ['viewportWidthPx', 'viewportHeightPx']),
    letterSize() {
      const extraHorizontalPadding = 30
      const horizontalMarginBetweenLetters = (this.maxLetters - 1) * 4

      const headerHeight = 40
      const keyboardHeight = 185
      const extraVerticalPadding = 65
      const verticalMarginBetweenLetters = (this.maxGuesses - 1) * 4

      const horizontalSpaceForLetters = this.viewportWidthPx - extraHorizontalPadding - horizontalMarginBetweenLetters
      const horizontalLetterSize = Math.min(horizontalSpaceForLetters / this.maxLetters, 80)
      const verticalSpaceForLetters = this.viewportHeightPx - headerHeight - keyboardHeight - extraVerticalPadding - verticalMarginBetweenLetters
      const verticalLetterSize = Math.min(verticalSpaceForLetters / this.maxGuesses, 80)

      return Math.min(horizontalLetterSize, verticalLetterSize)
    },
    letterStates() {
      return utilities.letterStatesForGuesses({answer: this.word, guesses: this.guesses})
    },
  },
  watch: {
    guesses() {
      this.checkAndPlayAnimation()
    },
    '$route.path'() {
      this.checkAndSetGameMode()
    },
  },
  methods: {
    ...Vuex.mapActions('Dashboard', ['setGameMode', 'handleKey']),
    checkAndSetGameMode() {
      if (this.$route.name === 'unlimited') {
        this.$root.addAlert({title: 'Try this one!', type: 'success'})
        this.setGameMode({gameMode: 'Unlimited', payload: this.$route.params.wordHash})
      } else if (this.$route.name === 'daily6') {
        this.$root.addAlert({title: `${dayjs().format('dddd')}'s 6-letter challenge`, type: 'success'})
        this.setGameMode({gameMode: 'Daily6'})
      } else if (this.$route.name === 'dashboard') {
        this.$root.addAlert({title: `${dayjs().format('dddd')}'s challenge`, type: 'success'})
        this.setGameMode({gameMode: 'Daily'})
      }
    },
    hintsForWord: utilities.hintsForWord,
    async checkAndPlayAnimation() {
      const paths = []
      for (let i in this.guesses) {
        for (let j in this.guesses[i]) {
          paths.push(`${i}-${j}`)
        }
      }
      await this.staggerAnimation(paths)
      if (this.finished) {
        setTimeout(() => this.showShare = true, 900)
      }
    },
    async staggerAnimation(paths) {
      this.animationLookup = {...this.animationLookup, [paths.shift()]: true}
      if (paths.length) {
        await new Promise(resolve => setTimeout(async () => { await this.staggerAnimation(paths); resolve() }, 20))
      }
    },
  },
}
</script>

<style scoped lang="sass">
.guess-words
  display: flex
  flex-direction: column
  align-items: center
.theme--dark
  .guess-word
    .guess-word-letter
      border-color: white
.guess-word
  display: flex
  .guess-word-letter
    display: flex
    align-items: center
    justify-content: center
    width: var(--letter-size)
    height: var(--letter-size)
    border: 1px solid black
    border-radius: 3px
    margin: 4px
    font-weight: bold
    border-color: rgba(0, 0, 0, 0.87)
    &.correct-letter
      border-color: #4CAF50
      background-color: #4CAF50
      color: white !important
    &.close-letter
      border-color: #ffeb3b
      background-color: #ffeb3b
      color: rgba(0, 0, 0, 0.87) !important
    &.finished
      backface-visibility: hidden
      animation: spin 0.3s linear 1
  &.guess-word-active
    .guess-word-letter
      border-width: 2px
.keyboard-wrapper
  display: flex
  justify-content: center
  margin-top: 12px
  .keyboard-inner-wrapper
    width: min(50vw, 500px)
@keyframes spin
  0%
    transform: rotateY(180deg)
  100%
    transform: rotateY(0deg)
</style>
