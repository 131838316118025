<template>
  <div
      class="simple-keyboard"
      :class="letterClasses"
      >
    <div class="keyboard-mount"></div>
  </div>
</template>

<script>
import Keyboard from "simple-keyboard"
import "simple-keyboard/build/css/index.css"

export default {
  name: "Keyboard",
  props: {
    letterStates: Object,
  },
  mounted() {
    this.keyboard = new Keyboard('keyboard-mount', {
      onKeyReleased: input => this.$emit('keyRelease', input),
      layout: {default: ['Q W E R T Y U I O P', 'A S D F G H J K L', 'Z X C V B N M', 'submit <&nbsp;backspace']}
    })
  },
  computed: {
    letterClasses() {
      let classes = []
      for (let letter in this.letterStates) {
        if (this.letterStates[letter] === 'off') {
          classes.push(`off-${letter}`)
        } else if (this.letterStates[letter] === 'close') {
          classes.push(`close-${letter}`)
        } else if (this.letterStates[letter] === 'correct') {
          classes.push(`correct-${letter}`)
        }
      }
      return classes
    },
  },
  methods: {
  },
}
</script>

<style scoped lang="sass">
$letters: 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
.theme--dark
  .keyboard-mount
    background-color: #121212
    ::v-deep
      .hg-standardBtn, .hg-functionBtn
        border: 1px solid white
        background-color: #121212
        &.hg-activeButton
          background-color: #363636
@each $letter in $letters
  .simple-keyboard.off-#{$letter} ::v-deep [data-skbtn=#{$letter}]
      background-color: #121212
      color: white
  .simple-keyboard.close-#{$letter} ::v-deep [data-skbtn=#{$letter}]
      background-color: yellow
      color: black
  .simple-keyboard.correct-#{$letter} ::v-deep [data-skbtn=#{$letter}]
      background-color: green
      color: white
  .theme--dark
    .simple-keyboard.off-#{$letter} ::v-deep [data-skbtn=#{$letter}]
        background-color: white
        color: #121212
    .simple-keyboard.close-#{$letter} ::v-deep [data-skbtn=#{$letter}]
        background-color: yellow
    .simple-keyboard.correct-#{$letter} ::v-deep [data-skbtn=#{$letter}]
        background-color: green
</style>
