<template>
  <v-container
      fill-height
      class="py-0"
      >
    <v-row
        class="text-center"
        style="height: 100%"
        >
      <v-col
          cols="12"
          class="pb-0"
          >
        <guess-board />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GuessBoard from '@/components/GuessBoard.vue'

export default {
  name: 'Dashboard',

  components: {
    GuessBoard,
  },
  data() {
    return {}
  },
}
</script>
