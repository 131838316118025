<template>
  <v-app
      v-resize="updateViewportDimensions"
      :style="{
        '--viewport-width-px': `${viewportWidthPx}px`,
        '--viewport-height-px': `${viewportHeightPx}px`,
      }"
      >
    <v-app-bar
      app
      height="40px"
    >
      <v-btn
          :to="{name: 'dashboard'}"
          class="d-flex align-center px-2"
          plain
          >
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo.png')"
          transition="scale-transition"
          width="30"
          height="30"
        />

        <h2>Bordle</h2>
      </v-btn>

      <v-spacer></v-spacer>

      <game-mode />
      <v-menu
          bottom
          left
          >
        <template #activator="{on}">
          <v-btn
              icon
              v-on="on"
              >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item class="px-0">
            <history />
          </v-list-item>
          <v-list-item class="px-0">
            <options />
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <dashboard />
      <alerts />
    </v-main>
  </v-app>
</template>

<style scoped lang="sass">
div ::v-deep .v-application--wrap
  width: var(--viewport-width-px)
  height: calc(var(--viewport-height-px))
  min-height: auto !important
</style>

<script>
import Vuex from 'vuex'
import Dashboard from '@/components/Dashboard.vue'
import GameMode from '@/components/GameMode.vue'
import History from '@/components/History.vue'
import Options from '@/components/Options.vue'
import Alerts from '@/components/Alerts.vue'

export default {
  name: 'App',
  components: {
    Dashboard,
    GameMode,
    History,
    Options,
    Alerts,
  },
  data() {
    return {
    }
  },
  created() {
    window.addEventListener('keydown', this.handleKey, true)
    this.updateViewportDimensions()
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKey, true)
  },
  computed: {
    ...Vuex.mapGetters('Config', ['viewportWidthPx', 'viewportHeightPx']),
  },
  methods: {
    ...Vuex.mapActions('Dashboard', ['handleKey']),
    ...Vuex.mapActions('Config', ['setConfig']),
    updateViewportDimensions() {
      this.setConfig({
        viewportWidthPx: window.innerWidth,
        viewportHeightPx: window.innerHeight,
      })
    },
  },
}
</script>
