import _ from 'lodash'
import dayjs from 'dayjs'

export default {
  todayDateString() {
    return dayjs().format('YYYY-MM-DD')
  },
  daysSinceEpoch() {
    return dayjs().diff('1970-01-01', 'day')
  },
  hintsForWord({answer, word}) {
    let hints = [], incorrectLetters = []
    for (let i in word) {
      if (word[i] === answer[i]) {
        hints[i] = 'correct'
      } else {
        incorrectLetters.push(answer[i])
      }
    }
    for (let i in word) {
      if (word[i] !== answer[i]) {
        const incorrectLetterIndex = incorrectLetters.findIndex(l => l === word[i])
        if (incorrectLetterIndex >= 0) {
          incorrectLetters.splice(incorrectLetterIndex, 1)
          hints[i] = 'close'
        }
      }
    }
    return hints
  },
  letterStatesForGuesses({answer, guesses}) {
    let letterStates = {}
    for (let guess of guesses) {
      const hints = this.hintsForWord({answer, word: guess})
      for (let i in guess) {
        if (hints[i] === 'correct') {
          letterStates[guess[i]] = 'correct'
        } else if (!['correct'].includes(letterStates[guess[i]]) && hints[i] === 'close') {
          letterStates[guess[i]] = 'close'
        } else if (!['correct', 'close'].includes(letterStates[guess[i]])) {
          letterStates[guess[i]] = 'off'
        }
      }
    }
    return letterStates
  },
  randomSlugString({length = 5} = {}) {
    return _.times(length, () => _.sample('abcdefghjkmnpqrstuvwxyz23456789'.split(''))).join('')
  },
}
