<template>
  <div class="d-flex align-center">
    <v-menu
        left
        >
      <template #activator="{on}">
        <v-btn
            text
            v-on="on"
            class="px-2"
            >
          {{ gameModeName }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list
          class="pt-0"
          >
        <v-list-item
            v-for="gameModeOption in gameModeOptions"
            :key="gameModeOption"
            @click="setGameModeAndCloseMenu(gameModeOption)"
            :class="{active: gameModeOption === gameModeName}"
            >
          {{ gameModeOption }}
        </v-list-item>
      </v-list>
    </v-menu>
    <new-word />
  </div>
</template>

<style scoped lang="sass">
.v-menu__content::v-deep
  margin-top: -10px
.active
  background-color: rgba(0, 0, 0, 0.2)
</style>

<script>
import Vuex from 'vuex'
import utilities from '@/lib/utilities'
import NewWord from '@/components/NewWord.vue'

export default {
  name: 'GameMode',
  components: {
    NewWord,
  },
  data() {
    return {
      gameModeOptions: [
        'Daily',
        'Daily 6',
        'Unlimited',
      ],
    }
  },
  computed: {
    ...Vuex.mapGetters('Dashboard', ['gameModeName']),
  },
  methods: {
    ...Vuex.mapActions('Dashboard', ['setGameMode']),
    setGameModeAndCloseMenu(gameMode) {
      if (gameMode === 'Daily') {
        this.$router.push({name: 'dashboard'})
      } else if (gameMode === 'Daily 6') {
        this.$router.push({name: 'daily6'})
      } else if (gameMode === 'Unlimited') {
        this.$router.push({name: 'unlimited', params: {wordHash: utilities.randomSlugString({length: 5})}})
      }
    },
  },
}
</script>
