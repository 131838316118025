var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [_c('span',_vm._g({},tooltipOn),[_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-history")]),_vm._v(" History ")],1)],1)]}}],null,true)},[_vm._v(" Daily History ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',[_c('v-select',{attrs:{"items":_vm.dailyTypeOptions,"hide-details":""},model:{value:(_vm.computedDailyType),callback:function ($$v) {_vm.computedDailyType=$$v},expression:"computedDailyType"}})],1),_c('v-card-text',[_c('div',{staticClass:"mb-2"},[_vm._v(" Win percentage: "+_vm._s(Math.ceil(((_vm.distribution.max - (_vm.distribution.spread[0] || 0)) / _vm.distribution.max) * 100))+"% ")]),_vm._l((_vm.maxGuesses),function(i){return _c('div',{key:(i + "-" + _vm.dailyType),staticClass:"d-flex flex-row mb-1"},[_c('div',{staticClass:"mr-1"},[_vm._v(_vm._s(i))]),_c('v-progress-linear',{attrs:{"value":(_vm.distribution.spread[i] / _vm.distribution.max) * 100,"color":"blue-grey","height":"25"}},[[_c('strong',[_vm._v(_vm._s(_vm.distribution.spread[i]))])]],2)],1)}),(_vm.averageScore)?_c('div',{staticClass:"mb-2"},[_vm._v("Average: "+_vm._s((_vm.averageScore || 0).toFixed(1)))]):_vm._e(),(_vm.sortedDays.length)?[_c('v-expansion-panels',_vm._l((_vm.sortedDays),function(day){return _c('v-expansion-panel',{key:day},[_c('v-expansion-panel-header',[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('div',{staticClass:"mr-3"},[_c('div',_vm._l((_vm.guesses[day].guesses),function(guess,i){return _c('div',{key:i,staticClass:"small-guess-word"},_vm._l((_vm.guesses[day].guesses[i]),function(guessLetter,j){return _c('div',{key:j,staticClass:"small-guess-letter",class:{
                              green: _vm.hintsForWord({answer: _vm.guesses[day].answer, word: _vm.guesses[day].guesses[i]})[j] === 'correct',
                              'white--text': _vm.hintsForWord({answer: _vm.guesses[day].answer, word: _vm.guesses[day].guesses[i]})[j] === 'correct',
                              yellow: _vm.hintsForWord({answer: _vm.guesses[day].answer, word: _vm.guesses[day].guesses[i]})[j] === 'close',
                            }})}),0)}),0)]),_vm._v(" "+_vm._s(day)+" ")])]),_c('v-expansion-panel-content',[_c('div',_vm._l((_vm.guesses[day].guesses),function(guess,i){return _c('div',{key:i,staticClass:"large-guess-word"},_vm._l((_vm.guesses[day].guesses[i]),function(guessLetter,j){return _c('div',{key:j,staticClass:"large-guess-letter",class:{
                          green: _vm.hintsForWord({answer: _vm.guesses[day].answer, word: _vm.guesses[day].guesses[i]})[j] === 'correct',
                          'white--text': _vm.hintsForWord({answer: _vm.guesses[day].answer, word: _vm.guesses[day].guesses[i]})[j] === 'correct',
                          yellow: _vm.hintsForWord({answer: _vm.guesses[day].answer, word: _vm.guesses[day].guesses[i]})[j] === 'close',
                        }},[_vm._v(" "+_vm._s(_vm.guesses[day].guesses[i][j])+" ")])}),0)}),0),_vm._v(" Answer: "+_vm._s(_vm.guesses[day].answer)+" ")])],1)}),1)]:_c('div',[_vm._v(" No recorded scores ")])],2),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Close ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }