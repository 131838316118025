import _ from 'lodash'
import utilities from '@/lib/utilities'
import Daily from '@/store/modules/Dashboard/Daily'
import Daily6 from '@/store/modules/Dashboard/Daily6'
import Unlimited from '@/store/modules/Dashboard/Unlimited'

export default {
  namespaced: true,
  modules: {
    Daily,
    Daily6,
    Unlimited,
  },
  state: {
    gameMode: 'Daily',
  },
  getters: {
    gameMode(state) { return state.gameMode },
    gameModeName(state, getters, rootState, rootGetters) {
      return rootGetters[`Dashboard/${state.gameMode}/gameModeName`]
    },
    maxLetters(state, getters, rootState, rootGetters) {
      return rootGetters[`Dashboard/${state.gameMode}/maxLetters`]
    },
    maxGuesses(state, getters, rootState, rootGetters) {
      return rootGetters[`Dashboard/${state.gameMode}/maxGuesses`]
    },
    guessableWordList(state, getters, rootState, rootGetters) {
      return rootGetters[`Dashboard/${state.gameMode}/guessableWordList`]
    },
    word(state, getters, rootState, rootGetters) {
      return rootGetters[`Dashboard/${state.gameMode}/word`]
    },
    guesses(state, getters, rootState, rootGetters) {
      return rootGetters[`Dashboard/${state.gameMode}/guesses`]
    },
    activeGuess(state, getters, rootState, rootGetters) {
      return rootGetters[`Dashboard/${state.gameMode}/activeGuess`]
    },
    finished(state, getters, rootState, rootGetters) {
      return rootGetters[`Dashboard/${state.gameMode}/finished`]
    },
  },
  mutations: {
    setGameMode(state, gameMode) {
      state.gameMode = gameMode
    },
  },
  actions: {
    initialize({getters, dispatch}, payload) {
      dispatch(`Dashboard/${getters.gameMode}/initialize`, payload, {root: true})
    },
    setGameMode({commit, dispatch}, {gameMode, payload}) {
      commit('setGameMode', gameMode)
      dispatch('initialize', payload)
    },
    handleKey({getters, commit, dispatch}, event) {
      // Stop handling key presses if we've reached max guesses or gotten it correct
      if (!getters.finished) {
        // If typing a letter and not a metaKey
        if (!event.metaKey && event.key.match(/^[a-zA-Z]$/)) {
          if (getters.activeGuess.length >= getters.maxLetters) {
            commit(`Dashboard/${getters.gameMode}/setActiveGuess`, getters.activeGuess.slice(0, getters.maxLetters - 1) + event.key.toUpperCase(), {root: true})
          } else {
            commit(`Dashboard/${getters.gameMode}/setActiveGuess`, getters.activeGuess + event.key.toUpperCase(), {root: true})
          }
        } else if (['Enter', 'submit'].includes(event.key) && getters.activeGuess.length === getters.maxLetters) {
          if (getters.guessableWordList.includes(getters.activeGuess)) {
            commit(`Dashboard/${getters.gameMode}/setGuesses`, [...getters.guesses, getters.activeGuess], {root: true})
            commit(`Dashboard/${getters.gameMode}/setActiveGuess`, '', {root: true})
            dispatch('encourage')
          } else {
            commit('Alerts/addAlert', {title: 'Not a real word!', type: 'error'}, {root: true})
          }
        } else if (['Backspace', '<&nbsp;backspace'].includes(event.key)) {
          commit(`Dashboard/${getters.gameMode}/setActiveGuess`, getters.activeGuess.slice(0, getters.activeGuess.length - 1), {root: true})
        }
      }
    },
    encourage({getters, commit}) {
      const guessCount = getters.guesses.length
      const latestHints = utilities.hintsForWord({answer: getters.word, word: getters.guesses[getters.guesses.length - 1]})
      const secondLatestHints = utilities.hintsForWord({answer: getters.word, word: getters.guesses[getters.guesses.length - 2]})
      const scores = {
        latest: _.sumBy(latestHints, h => h === 'correct' ? 2 : (h === 'close' ? 1 : 0)),
        secondLatest: _.sumBy(secondLatestHints, h => h === 'correct' ? 2 : (h === 'close' ? 1 : 0)),
      }
      console.log(getters.finished, guessCount, scores)
      if (getters.finished) {
        if (guessCount === 1) {
          commit('Alerts/addAlert', {title: 'Um, world record?', type: 'success', duration: 3000}, {root: true})
        } else if (guessCount === 2) {
          commit('Alerts/addAlert', {title: 'You\'re on fire!', type: 'success', duration: 3000}, {root: true})
        } else if (guessCount === 3) {
          commit('Alerts/addAlert', {title: 'Very nice', type: 'success', duration: 3000}, {root: true})
        } else if (guessCount === 4) {
          commit('Alerts/addAlert', {title: 'Nothing wrong with that', type: 'success', duration: 3000}, {root: true})
        } else if (guessCount === 5) {
          commit('Alerts/addAlert', {title: 'Struggling?', type: 'success', duration: 3000}, {root: true})
        } else if (guessCount >= 6) {
          commit('Alerts/addAlert', {title: 'I mean, you finished right?', type: 'success', duration: 3000}, {root: true})
        }
      } else if (guessCount === 1) {
        if (scores.latest === 0) {
          commit('Alerts/addAlert', {title: 'I feel your pain :(', type: 'success'}, {root: true})
        } else if (scores.latest === 1) {
          commit('Alerts/addAlert', {title: 'Eh, it\'s something', type: 'success'}, {root: true})
        } else if (scores.latest >= 2 && scores.latest <= 3) {
          commit('Alerts/addAlert', {title: 'Nice start!', type: 'success'}, {root: true})
        } else if (scores.latest >= 4 && scores.latest <= 6) {
          commit('Alerts/addAlert', {title: 'Flying out the gate!', type: 'success'}, {root: true})
        } else if (scores.latest === 7) {
          commit('Alerts/addAlert', {title: 'Born to run!', type: 'success'}, {root: true})
        } else if (scores.latest >= 8) {
          commit('Alerts/addAlert', {title: 'Unreal!', type: 'success'}, {root: true})
        }
      } else if (guessCount > 1) {
        const scoreDifferential = scores.latest - scores.secondLatest
        if (scoreDifferential === 0) {
          commit('Alerts/addAlert', {title: 'Um, no', type: 'success'}, {root: true})
        } else if (scoreDifferential === 1) {
          commit('Alerts/addAlert', {title: 'Ok, progress is good', type: 'success'}, {root: true})
        } else if (scoreDifferential >= 2 && scoreDifferential <= 3) {
          commit('Alerts/addAlert', {title: 'Yes, on track!', type: 'success'}, {root: true})
        } else if (scoreDifferential >= 4 && scoreDifferential <= 6) {
          commit('Alerts/addAlert', {title: 'Now we\'re talking', type: 'success'}, {root: true})
        } else if (scoreDifferential === 7) {
          commit('Alerts/addAlert', {title: 'So close!', type: 'success'}, {root: true})
        } else if (scoreDifferential >= 8) {
          commit('Alerts/addAlert', {title: 'Agh! How was that not it!', type: 'success'}, {root: true})
        }
      }
    },
  },
}
