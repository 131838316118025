import _ from 'lodash'
import localforage from 'localforage'

import guessableWordList from '@/store/guessable-6-letter-word-list'
import words from '@/store/6-letter-word-list'
import utilities from '@/lib/utilities'

export default {
  namespaced: true,
  state: {
    gameModeName: 'Daily 6',
    maxLetters: 6,
    maxGuesses: 7,
    todayDateString: '',
    word: '',
    guessHistory: {},
    activeGuess: '',
  },
  getters: {
    gameModeName(state) { return state.gameModeName },
    maxLetters(state) { return state.maxLetters },
    maxGuesses(state) { return state.maxGuesses },
    guessableWordList() { return guessableWordList },
    guessHistory(state) { return state.guessHistory },
    word(state) { return state.word },
    guesses(state) { return _.get(state.guessHistory[state.todayDateString], 'guesses') || [] },
    activeGuess(state) { return state.activeGuess },
    finished(state, getters, rootState, rootGetters) { 
      return getters.guesses.length >= rootGetters['Dashboard/maxGuesses'] || getters.guesses[getters.guesses.length - 1] === getters.word
    },
  },
  mutations: {
    setTodayDateString(state, todayDateString) {
      state.todayDateString = todayDateString
    },
    setWord(state, word) {
      state.word = word
    },
    setGuessHistory(state, guessHistory) {
      state.guessHistory = {...guessHistory}
    },
    setActiveGuess(state, activeGuess) {
      state.activeGuess = activeGuess
      localforage.setItem('b-daily6-activeGuess', state.activeGuess)
    },
    setGuesses(state, guesses) {
      state.guessHistory = {...state.guessHistory, [state.todayDateString]: {answer: state.word, guesses}}
      localforage.setItem('b-daily6-guessHistory', JSON.stringify(state.guessHistory))
    },
  },
  actions: {
    async initialize({commit, state}) {
      let guessHistory = {}
      commit('setTodayDateString', utilities.todayDateString())
      commit('setWord', words[utilities.daysSinceEpoch() % words.length])
      try {
        guessHistory = JSON.parse(await localforage.getItem('b-daily6-guessHistory') || '{}')
      } catch(e) { } // eslint-disable-line
      commit('setGuessHistory', guessHistory)
      if (guessHistory[state.todayDateString]) {
        commit('setActiveGuess', (await localforage.getItem('b-daily6-activeGuess')) || '')
      }
    },
  },
}
