import localforage from 'localforage'
import Vuetify from '@/plugins/vuetify'

export default {
  namespaced: true,
  state: {
    settings: {},
  },
  getters: {
    settings(state) { return state.settings },
  },
  mutations: {
    changeSetting(state, change) {
      state.settings = {...state.settings, ...change}
      localforage.setItem('b-options', JSON.stringify(state.settings))
      Vuetify.framework.theme.dark = !!state.settings.darkMode
    },
  },
  actions: {
    async initialize({commit}) {
      commit('changeSetting', JSON.parse((await localforage.getItem('b-options')) || '{}'))
    },
  },
}
