const uuid = require('uuid')

export default {
  namespaced: true,
  state: {
    alerts: [],
  },
  getters: {
    alerts(state) { return state.alerts },
  },
  mutations: {
    addAlert(state, {title, type = 'error', duration = 2000}) {
      state.alerts = [...state.alerts, {title, type, duration, id: uuid.v4()}]
    },
    removeAlert(state, {id}) {
      state.alerts = [...state.alerts.filter(a => a.id !== id)]
    },
  },
  actions: {
  },
}
