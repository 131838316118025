<template>
  <div
      v-if="alerts"
      class="alert-container"
      >
    <v-slide-x-reverse-transition
        group
        >
      <template
          v-for="alert in alerts"
          >
        <div
            :key="alert.id"
            class="alert-container-alert"
            :class="{[`type-${alert.type}`]: true}"
            >
          {{alert.title}}
        </div>
      </template>
    </v-slide-x-reverse-transition>
  </div>
</template>

<style scoped lang="sass">
.alert-container
  display: flex
  position: fixed
  top: 50px
  right: 10px
  flex-direction: column
  align-items: flex-end
  justify-content: flex-start
  .alert-container-alert
    margin-bottom: 5px
    background-color: white
    border-radius: 4px
    color: black
    padding: 2px 6px
    &.type-error
      background-color: red
      color: white
    &.type-success
      background-color: green
      color: white
</style>

<script>
import Vuex from 'vuex'

export default {
  name: 'Alerts',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...Vuex.mapGetters('Alerts', ['alerts']),
  },
  watch: {
    alerts() {
      if (this.alerts.length) {
        for (let alert of this.alerts) {
          setTimeout(() => this.removeAlert({id: alert.id}), alert.duration)
        }
      }
    },
  },
  methods: {
    ...Vuex.mapMutations('Alerts', ['removeAlert']),
  },
}
</script>
