import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import Alerts from '@/store/modules/Alerts'
import Config from '@/store/modules/Config'
import Dashboard from '@/store/modules/Dashboard'
import Options from '@/store/modules/Options'

export default new Vuex.Store({
  modules: {
    Alerts,
    Config,
    Dashboard,
    Options,
  }
})
