import Vue from 'vue'
import Vuex from 'vuex'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import localforage from 'localforage'
localforage.setDriver(localforage.LOCALSTORAGE)

import VueRouter from 'vue-router'
Vue.use(VueRouter)
import Dashboard from '@/components/Dashboard.vue'
const router = new VueRouter({
  mode: 'history',
  routes: [
    {path: '/',                     name: 'dashboard',       component: Dashboard},
    {path: '/daily6',               name: 'daily6',          component: Dashboard},
    {path: '/unlimited/:wordHash',  name: 'unlimited',       component: Dashboard},
  ],
})

const main = async () => {
  await store.dispatch('Options/initialize')
  await store.dispatch('Dashboard/initialize')

  Vue.config.productionTip = false

  new Vue({
    vuetify,
    store,
    router,
    methods: {
      ...Vuex.mapMutations('Alerts', ['addAlert']),
    },
    render: h => h(App)
  }).$mount('#app')
}

main()
