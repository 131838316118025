import guessableWordList from '@/store/guessable-5-letter-word-list'
import words from '@/store/5-letter-word-list'

export default {
  namespaced: true,
  state: {
    gameModeName: 'Unlimited',
    maxLetters: 5,
    maxGuesses: 6,
    word: '',
    guesses: [],
    activeGuess: '',
  },
  getters: {
    gameModeName(state) { return state.gameModeName },
    maxLetters(state) { return state.maxLetters },
    maxGuesses(state) { return state.maxGuesses },
    guessableWordList() { return guessableWordList },
    word(state) { return state.word },
    guesses(state) { return state.guesses },
    activeGuess(state) { return state.activeGuess },
    finished(state, getters, rootState, rootGetters) { 
      return getters.guesses.length >= rootGetters['Dashboard/maxGuesses'] || getters.guesses[getters.guesses.length - 1] === getters.word
    },
  },
  mutations: {
    setWord(state, word) {
      state.word = word
    },
    setGuesses(state, guesses) {
      state.guesses = [...guesses]
    },
    setActiveGuess(state, activeGuess) {
      state.activeGuess = activeGuess
    },
  },
  actions: {
    async initialize({commit}, wordHash) {
      commit('setWord', words[wordHash.split('').reduce((memo,i) => { memo = memo + i.charCodeAt(0); return memo}, 0) % words.length])
      commit('setGuesses', [])
      commit('setActiveGuess', '')
    },
  },
}
