<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      scrollable
      >
    <template #activator="{on}">
      <v-tooltip
          bottom
          >
        <template #activator="{on: tooltipOn}">
          <span v-on="tooltipOn">
            <v-btn
                v-on="on"
                text
                >
              <v-icon class="mr-2">mdi-history</v-icon>
              History
            </v-btn>
          </span>
        </template>
        Daily History
      </v-tooltip>
    </template>
    <template #default="dialog">
      <v-card>
        <v-card-title>
          <v-select
              v-model="computedDailyType"
              :items="dailyTypeOptions"
              hide-details
              />
        </v-card-title>
        <v-card-text>
          <div class="mb-2">
            Win percentage: {{ Math.ceil(((distribution.max - (distribution.spread[0] || 0)) / distribution.max) * 100) }}%
          </div>
          <div
              v-for="i in maxGuesses"
              :key="`${i}-${dailyType}`"
              class="d-flex flex-row mb-1"
              >
            <div class="mr-1">{{ i }}</div>
            <v-progress-linear
              :value="(distribution.spread[i] / distribution.max) * 100"
              color="blue-grey"
              height="25"
              >
              <template>
                <strong>{{ distribution.spread[i] }}</strong>
              </template>
            </v-progress-linear>
          </div>

          <div v-if="averageScore" class="mb-2">Average: {{ (averageScore || 0).toFixed(1) }}</div>
          <template v-if="sortedDays.length">
            <v-expansion-panels>
              <v-expansion-panel
                  v-for="day of sortedDays"
                  :key="day"
                  >
                <v-expansion-panel-header>
                  <div class="d-flex justify-start align-center">
                    <div class="mr-3">
                      <div>
                        <div
                            v-for="(guess,i) in guesses[day].guesses"
                            :key="i"
                            class="small-guess-word"
                            >
                          <div
                              v-for="(guessLetter,j) in guesses[day].guesses[i]"
                              :key="j"
                              class="small-guess-letter"
                              :class="{
                                green: hintsForWord({answer: guesses[day].answer, word: guesses[day].guesses[i]})[j] === 'correct',
                                'white--text': hintsForWord({answer: guesses[day].answer, word: guesses[day].guesses[i]})[j] === 'correct',
                                yellow: hintsForWord({answer: guesses[day].answer, word: guesses[day].guesses[i]})[j] === 'close',
                              }"
                              >
                          </div>
                        </div>
                      </div>
                    </div>
                    {{day}}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <div
                        v-for="(guess,i) in guesses[day].guesses"
                        :key="i"
                        class="large-guess-word"
                        >
                      <div
                          v-for="(guessLetter,j) in guesses[day].guesses[i]"
                          :key="j"
                          class="large-guess-letter"
                          :class="{
                            green: hintsForWord({answer: guesses[day].answer, word: guesses[day].guesses[i]})[j] === 'correct',
                            'white--text': hintsForWord({answer: guesses[day].answer, word: guesses[day].guesses[i]})[j] === 'correct',
                            yellow: hintsForWord({answer: guesses[day].answer, word: guesses[day].guesses[i]})[j] === 'close',
                          }"
                          >
                        {{ guesses[day].guesses[i][j] }}
                      </div>
                    </div>
                  </div>
                  Answer: {{ guesses[day].answer }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
          <div v-else>
            No recorded scores
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="dialog.value = false"
              >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<style scoped lang="sass">
.small-guess-word
  line-height: 5px
  .small-guess-letter
    display: inline-block
    width: 5px
    height: 5px
    border-radius: 2px
    border: 1px solid grey
    margin-right: 2px
.large-guess-word
  line-height: 10px
  .large-guess-letter
    display: inline-flex
    justify-content: center
    align-items: center
    width: 14px
    height: 14px
    border-radius: 2px
    border: 1px solid grey
    margin-right: 5px
    margin-bottom: 5px
    font-size: 10px
</style>

<script>
import _ from 'lodash'
import utilities from '@/lib/utilities'

export default {
  name: 'History',

  components: {
  },
  data() {
    return {
      dailyType: 'Daily',
      dailyTypeOptions: [
        {text: 'Daily History', value: 'Daily'},
        {text: 'Daily 6 History', value: 'Daily6'},
      ],
    }
  },
  computed: {
    maxGuesses() {
      return this.$store.getters[`Dashboard/${this.dailyType}/maxGuesses`]
    },
    computedDailyType: {
      get() { return this.dailyType },
      set(v) { this.dailyType = v },
    },
    distribution() {
      const max = Object.values(this.guessHistory).length
      return {
        max,
        spread: Object.values(this.guessHistory).reduce((memo,day) => {
          let solvedIn = day.guesses.findIndex(guess => guess === day.answer) + 1
          _.set(memo, [solvedIn], _.get(memo, [solvedIn], 0) + 1)
          return memo
        }, [])
      }
    },
    guessHistory() {
      return this.$store.getters[`Dashboard/${this.dailyType}/guessHistory`]
    },
    sortedDays() {
      return Object.keys(this.guessHistory).sort().reverse()
    },
    guesses() {
      return _.pick(this.guessHistory, this.sortedDays)
    },
    averageScore() {
      return this.distribution.spread.reduce((memo,v,i) => { memo = memo + (v || 0) * i; return memo; }, 0) / this.distribution.max
    },
  },
  methods: {
    hintsForWord: utilities.hintsForWord,
  },
}
</script>
