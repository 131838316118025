<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      scrollable
      :value="value"
      @input="$emit('input', false)"
      style="overflow: visible"
      >
    <template #default>
      <v-card>
        <v-btn
            absolute
            top
            right
            icon
            @click="$emit('input', false)"
            >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Share your score
        </v-card-title>
        <v-card-text>
          <div
              v-for="(message,i) in messageArray"
              :key="i"
              >
            {{ message }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              depressed
              color="green"
              dark
              @click="share"
              >
            SHARE
            <v-icon>mdi-share</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<style scoped lang="sass">
</style>

<script>
import Vuex from 'vuex'
import dayjs from 'dayjs'
import utilities from '@/lib/utilities'

export default {
  name: 'ShareDialog',
  components: {
  },
  props: {
    value: null,
  },
  data() {
    return {
    }
  },
  computed: {
    ...Vuex.mapGetters('Dashboard', ['guesses', 'word', 'gameModeName']),
    messageArray() {
      return [
        `Bordle ${this.gameModeName} in ${this.guesses.length} - ${dayjs().format('M/D')}`,
        ...this.guesses.map(guess => {
          const hints = utilities.hintsForWord({answer: this.word, word: guess})
          return guess.split('').map((g,i) => hints[i] === 'correct' ? '🟩' : (hints[i] === 'close' ? '🟨' : '⬛')).join('')
        }),
      ]
    },
    message() {
      return this.messageArray.join('\n')
    },
  },
  methods: {
    share() {
      this.show = false
      if (navigator.share) {
        navigator.share({
          title: this.messageArray[0],
          url: document.location.href,
          text: `${this.message}\n`,
        })
      } else if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(`${this.message}\n${document.location.href}`)
        this.$root.addAlert({title: 'Copied to clipboard', type: 'success'})
      }
    },
  },
}
</script>
