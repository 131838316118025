<template>
  <v-tooltip
      bottom
      >
    <template #activator="{on}">
      <v-btn
          icon
          small
          v-on="on"
          @click="newWord"
          >
        <v-icon class="mr-2">mdi-refresh</v-icon>
      </v-btn>
    </template>
    New word
  </v-tooltip>
</template>

<style scoped lang="sass">
</style>

<script>
import utilities from '@/lib/utilities'

export default {
  name: 'NewWord',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    newWord() {
      this.$router.push({name: 'unlimited', params: {wordHash: utilities.randomSlugString({length: 5})}})
    },
  },
}
</script>
